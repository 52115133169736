import React from 'react'
import { FaLinkedin, FaGithub, FaFacebook, FaInstagram } from "react-icons/fa";
import BackgroundGradient from '../../BackgroundGradient';

const Socials = [
    {
        href:"https://www.linkedin.com/in/keionv",
        logo:<FaLinkedin size={40}/>
    },
    {
        href:"https://github.com/notKeion/",
        logo: <FaGithub size={40}/>
    },
    {
        href:"https://www.instagram.com/keionvergara/",
        logo: <FaInstagram size={40}/>
    },
    {
        href:'#',
        logo:<FaFacebook size={40}/>
    }
]

const SocialMediaCards = () => {
  return (
    <div className='w-full h-full flex flex-wrap'>
        {Socials.map((social)=>(
            <div className='flex lg:w-[50%] w-[100%] lg:h-[50%] h-[25%] justify-center items-center'>
                <div className='p-4 rounded-full m-4'>
                    {social.logo}
                    </div>
            </div>
        ))}
    </div>
  )
}

export default SocialMediaCards