import React from "react";
import SocialMediaCards from "./Cards/SocialMediaCards";
import BentoCard from "./Cards/BentoCard";
import MyProjectsCard from "./Cards/MyProjectsCard";
import CofICard from "./Cards/CofICard";
import MITCard from "./Cards/MITCard";
import SigmaChiCard from "./Cards/SigmaChiCard";
import ResumeCard from "./Cards/ResumeCard";
import LeetCodeCard from "./Cards/LeetCodeCard";



const BentoPage = () => {
  return (
    <div className="h-screen ">
      <div className="m-auto max-w-[1366px] grid h-[100%] lg:grid-cols-5 grid-cols-4 lg:grid-rows-3 grid-rows-6 gap-2 py-20 px-5 pb-20">
        <BentoCard className="col-span-2 row-span-2">
            <MyProjectsCard/>
        </BentoCard>

        <BentoCard className="lg:col-span-3 col-span-2 row-span-1">
          <SigmaChiCard/>
        </BentoCard>
        <BentoCard className="lg:col-span-2 col-span-2 row-span-1">
          <LeetCodeCard/>
        </BentoCard>

        <BentoCard className="lg:col-span-1 col-span-1 row-span-1">
          <ResumeCard/>
        </BentoCard>
        <BentoCard className="lg:col-span-2 col-span-3 lg:row-span-1 row-span-2">
          <CofICard/>
        </BentoCard>
        <BentoCard className="lg:col-span-1 col-span-1 lg:row-span-1 row-span-3">
          <SocialMediaCards />
        </BentoCard>
        <BentoCard className="flex lg:col-span-2 col-span-3 lg:row-span-1 row-span-2 ">
            <MITCard/>
        </BentoCard>
      </div>
    </div>
  );
};

export default BentoPage;
