import React from "react";
import EducationCard from "./EducationCard";

const MITCard = () => {
  return (
    <div className="group flex flex-col w-full h-full overflow-hidden ">
      <EducationCard
        title="Massachusetts Institute of Technology"
        subtitle={"Certificate"}
        dates={"June 2024 - Present"}
        img={<img src="./MIT-Logo.png" />}
      >
        <p className="text-center font-bold ">
        Applied Data Sceince and Machine Learning
        </p>
      </EducationCard>
    </div>
  );
};

export default MITCard;
