import React from "react";
import { motion } from "framer-motion";

const Badge = ({ badgeData }) => {
  return (
    <div className="flex p-2 size-[100%]">
      <div className="flex items-center h-[100%]">{badgeData.image}</div>
    </div>
  );
};

const BadgePanel = ({ data }) => {
  const StaggerOpen = {
    hidden: (i) => ({ 
        opacity: 0, 
        y: -20 * i - 10,
        scale: 0.3 
    }),
    animate: (i) => ({
      opacity: 1,
      y:0,
      scale: 1,
      transition: { type: "spring", stiffness: 100, delay: 0.1 * i },
    }),
    exit: {
        opacity: 0
    }
  };
  return (
    <div className="flex gap-2 justify-center sm:justify-normal h-[100px]">
      {data.badges.map((badgeData, index) => (
        <motion.div
          variants={StaggerOpen}
          initial="hidden"
          animate="animate"
          exit={'exit'}
          custom={index}
        >
          <Badge badgeData={badgeData} key={index} index={index} />
        </motion.div>
      ))}
    </div>
  );
};

export default BadgePanel;
