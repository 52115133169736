import React from 'react'
import BackgroundGradient from '../../BackgroundGradient';

const LeetCodeCard = () => {
  return (
      <div
        className="flex h-full w-full"
        style={{
          maskImage: "url(./leetcode.svg)",
          maskRepeat: "no-repeat",
          maskSize: "100% 100%",
        }}
      >
        <BackgroundGradient disableRounding className={"w-[105%] h-[105%]"} />
        {/* <div className="flex h-full w-full bg-[radial-gradient(circle_farthest-side_at_0_100%,#00ccb1,transparent),radial-gradient(circle_farthest-side_at_100%_0,#7b61ff,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#ffc414,transparent),radial-gradient(circle_farthest-side_at_0_0,#1ca0fb,#141316)]" /> */}
      </div>
  )
}

export default LeetCodeCard