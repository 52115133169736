// StackGraphic.jsx
import React, { useRef, useEffect } from 'react';
import { motion, useInView } from 'framer-motion';

const StackGraphic = ({ selectedLayer, setSelectedLayer, setPaused, layers }) => {

  const OpenAnimation = {
    hidden: { opacity: 0.3, y: 0, scale: 0.3 },
    animate: (i) => ({
      opacity: 0.7,
      y: -20 * i - 10,
      scale: 0.9,
      transition: { type: 'spring', stiffness: 100, delay: 0.1*i }
    })
  };

  const LayerAnimation = {
    initial: { scale: 1 },
    selected: { scale: 1.2 }
  };

  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  return (
    <motion.svg height="100%" viewBox="0 0 114 135" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref}>
      <g opacity="0.9" > {/* Base */}
        <path d="M1 104L56 118L113 104L56 89L1 104Z" fill="#141316" /> {/* Top of Base */}
        <path d="M1 120L56 134V118L1 104V120Z" fill="#141316" />
        <path d="M56 134L113 120V104L56 118V134Z" fill="#141316" />
        <path
          d="M56 134L1 120V104M56 134L113 120V104M56 134V118M1 104L56 118M1 104L56 89L113 104M56 118L113 104"
          stroke="#1E1E1E"
          strokeOpacity="0.5"
          strokeWidth="0.5"
        />
      </g>
      {layers.map((layer, index) => (
        <motion.g
          key={index}
          initial="hidden"
          variants={OpenAnimation}
          custom={index}
          onMouseEnter={() => {
            setSelectedLayer(index);
            setPaused(true);
          }}
          onMouseLeave={() => {
            setPaused(false);
          }}
          animate={isInView ? 'animate' : 'hidden'}
        >
          <motion.path
            fill={index === selectedLayer ? layer.color : '#FFF'}
            variants={LayerAnimation}
            animate={index === selectedLayer ? 'selected' : 'initial'}
            d="M1 104L56 118L113 104L56 89L1 104Z"
          />
        </motion.g>
      ))}
    </motion.svg>
  );
};

export default StackGraphic;
