import React from 'react'
import BackgroundGradient from '../../BackgroundGradient';

const BentoCard = ({ className, children }) => {
    return (
      <div className={"flex " + className}>
        <BackgroundGradient className={"w-full h-full"}>
          <div className="w-full h-full rounded-[22px] p-4 bg-white dark:bg-zinc-900">
            {children}
          </div>
        </BackgroundGradient>
      </div>
    );
  };

export default BentoCard