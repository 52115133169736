import React from "react";
import AnimatedBlob from "../AnimatedBlob";
import "../../App.css";
import AccentText from "../AccentText";
import BackgroundGradient from "../BackgroundGradient";
import GradientButton from "../GradientButton";

const HeroPage = () => {
  const colors = [
    "#222", // Very dark gray (almost black)
    "#555", // Dark gray
    "#888", // Medium gray
    "#AAA", // Gray
    "#CCC", // Light gray
    "#EEE", // Very light gray
    "#FFF", // White
  ];
  return (
    <div className="relative flex justify-end  bg-zinc-900 h-svh">
      <div className="flex justify-end dottedBackground h-[100%] sm:w-[40%] w-[100%]">
        <AnimatedBlob colors={colors} className={"w-[200%] h-[100%]"} />
      </div>
      <div className="absolute inset-0">
        <div className="m-auto max-w-[1366px] grid h-[100%] items-center px-5">
          <div className="grid">
            <span className="font-black text-8xl">
              <AccentText>Hey</AccentText>, I'm Keion<AccentText>.</AccentText>
            </span>
            <span className="font-normal text-4xl">
              I'm an aspiring <AccentText>full stack developer</AccentText>.
            </span>
          </div>
          <div className="flex">
            <GradientButton>
              <a href="#contact">
                <div className="bg-black p-4 rounded-full font-extrabold text-xl">
                  Contact Me
                </div>
              </a>
            </GradientButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroPage;
