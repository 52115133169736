import React,{useRef, useState} from "react";
import BackgroundGradient from "../BackgroundGradient";
import FrameContainer from "../FrameContainer";
import GradientButton from "../GradientButton";
import { FaLinkedin, FaGithub, FaFacebook, FaInstagram } from "react-icons/fa";
import AnimatedBlob from "../AnimatedBlob";
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const form = useRef();
  const SOCIAL_ICON_SIZE = 18; 
  const [emailSent, setEmailSent] = useState(false)
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_w1d9bso', 'template_nwfn5yr', form.current, {
        publicKey: 'dpFjLQYED3uvvZx3P',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setEmailSent(true)
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className="max-w-[700px] flex-grow ">
      <BackgroundGradient>
        <div className="rounded-[22px] p-4 sm:p-10 dark:bg-zinc-900 ">
          <div className="grid grid-cols-2 gap-10">
            <span className="text-center col-span-2 font-extrabold text-2xl">
              Lets Connect!
            </span>
            <form ref={form} onSubmit={sendEmail} className="grid gap-5">
              <div className="grid">
                Full Name <input type="text" name="from_name" className="bg-transparent border-b-2 " />
              </div>
              <div className="grid">
                Email <input type="email" name="from_email" className="bg-transparent border-b-2 " />
              </div>
              <div className="grid">
                Message <input name="message" className="bg-transparent border-b-2 " />
              </div>
              <div className="w-[100%] ">
              <GradientButton >
                <button type="submit" value="Send" disabled={emailSent}>
                  <div className="bg-black p-4 rounded-full font-extrabold text-xl">
                    {emailSent ? "Email Sent!" :"Contact Me" }
                  </div>
                </button>
              </GradientButton>

              </div>
            </form>
            <div className="grid grid-rows-3 gap-5">
              <div className="flex flex-col justify-center gap-1">
                <span className="font-light text-xl">Contact</span>
                <div className="flex flex-col text-zinc-400">
                  <span>keionv11@gmail.com</span>
                  <span>+1 (986) 837-0545</span>
                </div>
              </div>
              <div className="flex flex-col justify-center gap-1">
                <span className="font-light text-xl">Based In</span>
                <div className="flex flex-col text-zinc-400">
                  <span>Boise, ID</span>
                  <span>Winnipeg, MB</span>
                </div>
              </div>
              <div
                className="flex items-center justify-between  p-5"
              >
                <a href="https://www.linkedin.com/in/keionv">
                  <FaLinkedin size={SOCIAL_ICON_SIZE} />
                </a>
                <a href="https://github.com/notKeion/">
                  <FaGithub size={SOCIAL_ICON_SIZE} />
                </a>
                <a href="https://www.instagram.com/keionvergara/">
                  <FaInstagram size={SOCIAL_ICON_SIZE} />
                </a>
                <a href="#">
                  <FaFacebook size={SOCIAL_ICON_SIZE} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </BackgroundGradient>
    </div>
  );
};

const ContactPage = () => {
  return (
    <div className="bg-zinc-900 dottedBackground h-screen">
      <div className="m-auto max-w-[1366px] grid h-[100%] items-center py-20 px-5 ">
        <div className="grid gap-10">
          <span className="font-black text-6xl">Contact Me? </span>

          <div className="flex flex-wrap justify-center w-[100%] ">

            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
