import React from "react";
import { animate, motion } from "framer-motion";

const FrameContainer = ({ children, className, animated = false }) => {
  const borderVariants = {
    hidden: {
      top: 0,
      left: 0,
      right: "100%",
      bottom: "100%",
    },
    animate: {
      top: 0,
      left: 0,
      right: ["100%", "0%"],
      bottom: ["100%", "100%", "0%"],
      transition: {
        right: { delay: 0.35, duration: 0.35, ease: "easeInOut" },
        bottom: { delay: 0.5, duration: 0.5, ease: "easeInOut" },
      },
    },
  };
  const containerVarients = {
    hidden: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        delay: 0.5,
        duration: 1,
        ease: "easeInOut",
      },
    },
  };
  const cornerVarients = {
    animate: {
      scale: [1, 5.5, 1],
      transition: {
        scale: { duration: 0.5, ease: "easeInOut" },
      },
    },
  };
  const otherCornerVariants = {
    hidden: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        opacity: { delay: 0.35, duration: 0.5, ease: "easeInOut" },
      },
    },
  };

  return (
    <div className={"relative mx-auto w-[100%] " + className}>
      {/* Adding a little pop animation to this star */}

      <motion.svg
        variants={animated ? cornerVarients : null}
        whileInView={"animate"}
        xmlns="http://www.w3.org/2000/svg"
        fill="white"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="absolute h-6 w-6 -top-3 -left-3 dark:text-white text-black"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 6v12m6-6H6"
        ></path>
      </motion.svg>
      <motion.div
        variants={animated ? otherCornerVariants : null}
        whileInView={"animate"}
        initial="hidden"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="white"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="absolute h-6 w-6 -bottom-3 -left-3 dark:text-white text-black"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 6v12m6-6H6"
          ></path>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="white"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="absolute h-6 w-6 -top-3 -right-3 dark:text-white text-black"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 6v12m6-6H6"
          ></path>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="white"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="absolute h-6 w-6 -bottom-3 -right-3 dark:text-white text-black"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 6v12m6-6H6"
          ></path>
        </svg>
      </motion.div>

      <motion.div
        className="border border-white/[0.2] absolute top-0 left-0 right-0 bottom-0 origin-top-left z-99"
        variants={animated ? borderVariants : null}
        initial={"hidden"}
        whileInView={"animate"}
      />
      <motion.div
        initial="hidden"
        whileInView="animate"
        className=""
        variants={animated ? containerVarients : null}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default FrameContainer;
