// TechStack.jsx
import React, { useState, useEffect } from "react";
import StackGraphic from "./StackGraphic";
import { motion } from "framer-motion";
import FrameContainer from "../FrameContainer";
import AccentText from "../AccentText";
import BadgePanel from "./BadgePanel";
import { ReactComponent as PythonLogo } from "./Logos/icons8-python.svg";
import { ReactComponent as ReactLogo } from "./Logos/icons8-react.svg";
import { ReactComponent as TailwindLogo } from "./Logos/icons8-tailwind-css.svg";
import { ReactComponent as JavaLogo } from "./Logos/icons8-java.svg";
import { ReactComponent as JavascriptLogo } from "./Logos/icons8-js.svg";
import { ReactComponent as GraphQLLogo } from "./Logos/icons8-graphql.svg";
import { ReactComponent as DjangoLogo } from "./Logos/icons8-django.svg";
import { ReactComponent as PostgreSQLLogo } from "./Logos/icons8-postgresql.svg";
import { ReactComponent as FirebaseLogo } from "./Logos/icons8-firebase.svg";
import { ReactComponent as GitLogo } from "./Logos/icons8-git.svg";
import { ReactComponent as DockerLogo } from "./Logos/icons8-docker.svg";
import { ReactComponent as AWSLogo } from "./Logos/icons8-aws.svg";
import { ReactComponent as VSCodeLogo } from "./Logos/icons8-vs-code.svg";
import { ReactComponent as NodeLogo } from "./Logos/icons8-nodejs.svg";
import { ReactComponent as SupabaseLogo } from "./Logos/supabase-seeklogo.svg";
import { ReactComponent as RedisLogo } from "./Logos/icons8-redis.svg";
import { ReactComponent as FaunaLogo } from "./Logos/icon-fauna.svg";
import { ReactComponent as MongoDBLogo } from "./Logos/icons8-mongodb.svg";


const TechStack = () => {
  const [selectedLayer, setSelectedLayer] = useState(3);
  const [paused, setPaused] = useState(false);

  const layerData = [
    {
      title: "Devops",
      color: "#00ccb1",
      badges: [
        {image: <FirebaseLogo width={'100%'} height={'100%'} fill="white" />},
        {image: <GitLogo width={'100%'} height={'100%'} fill="white" />},
        {image: <DockerLogo width={'100%'} height={'100%'} fill="white" />},
        {image: <AWSLogo width={'100%'} height={'100%'} fill="white" />},
        {image: <VSCodeLogo width={'100%'} height={'100%'} fill="white" />},

    ],
    },
    {
      title: "Databases",
      color: "#33b4c9",
      badges: [
        { image: <PostgreSQLLogo width={'100%'} height={'100%'} fill="white" /> },
        { image: <RedisLogo width={'100%'} height={'100%'} fill="white" /> },
        { image: <FaunaLogo width={'100%'} height={'100%'} fill="white" /> },
        { image: <MongoDBLogo width={'100%'} height={'100%'} fill="white" /> },
      ],
    },
    {
      title: "Backend",
      color: "#669cdc",
      badges: [
        { image: <GraphQLLogo width={'100%'} height={'100%'} fill="white" /> },
        { image: <DjangoLogo width={'100%'} height={'100%'} fill="white" /> },
        { image: <SupabaseLogo width={'100%'} height={'100%'} fill="white" /> },
        { image: <NodeLogo width={'100%'} height={'100%'} fill="white" /> },
      ],
        
    },
    {
      title: "Frontend",
      color: "#9970ee",
      badges: [
        { image: <PythonLogo width={'100%'} height={'100%'} fill="white" /> },
        { image: <JavaLogo width={'100%'} height={'100%'} fill="white" /> },
        { image: <JavascriptLogo width={'100%'} height={'100%'} fill="white" />},
        { image: <ReactLogo width={'100%'} height={'100%'} fill="lightblue" /> },
        { image: <TailwindLogo width={'100%'} height={'100%'} fill="white" /> },
      ],
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        setSelectedLayer((prevSelectedLayer) =>
          prevSelectedLayer === 0 ? 3 : prevSelectedLayer - 1
        );
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [paused]);

  return (
    <div className="h-screen">
      <div className="m-auto max-w-[1366px] grid h-[100%] grid-cols-1 sm:grid-cols-2 px-5 pb-20">

        <div className="flex  h-[100%] sm:justify-start justify-center ">
          <div className="flex flex-col h-[100%] sm:w-[100%] max-w-[500px] w-auto">
            <span className="text-6xl font-black mt-[100px]">
              My <AccentText>Fullstack</AccentText>
            </span>
            <StackGraphic
              selectedLayer={selectedLayer}
              setSelectedLayer={setSelectedLayer}
              paused={paused}
              setPaused={setPaused}
              layers={layerData}
            />
          </div>
        </div>
        {/* Why is there a huge gap here when the grid-cols is 1. this gap only appears when i have the height set to 80% as  */}

        <div className="flex flex-col justify-center  w-[100%] h-[100%] ">
          <motion.span
            key={selectedLayer}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{ color: layerData[selectedLayer].color }}
            className="flex font-black text-3xl justify-center "
          >
            {layerData[selectedLayer].title}
          </motion.span>
          <FrameContainer animated className={'w-[100%] '}>
            <BadgePanel data={layerData[selectedLayer]} key={selectedLayer} />
          </FrameContainer>
        </div>
      </div>
    </div>
  );
};

export default TechStack;
