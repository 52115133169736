import React from "react";
import FrameContainer from "../../FrameContainer";
import EducationCard from "./EducationCard";



const CofICard = () => {
  return (
    <div className="group flex flex-col w-full h-full overflow-hidden">
      <EducationCard
        title="The College of Idaho"
        subtitle={"B.F.A"}
        dates={"August 2022 - Present"}
        img={<img src="./collegeofidaho.png" />}
      >
        <div className="lg:text-base text-xs  grid px-2">
          <div className="grid grid-cols-3 ">
            Majors
            <div className="grid col-span-2 grid-cols-2">
              <FrameContainer className={"p-1"}>
                Comp Sci
              </FrameContainer>
              <FrameContainer className={"p-1"}>Business</FrameContainer>
            </div>
          </div>
          <div className="grid grid-cols-3">
            Minor
            <FrameContainer className={"col-span-2, p-1"}>
              Communications
            </FrameContainer>
          </div>
          <div className="grid grid-cols-3">
            Emphasis
            <div className="grid col-span-2 grid-cols-2">
              <FrameContainer className={"p-1"}>Data Science</FrameContainer>
              <FrameContainer className={"p-1"}>Marketing</FrameContainer>
            </div>
          </div>
        </div>
      </EducationCard>
    </div>
  );
};

export default CofICard;
