import React from 'react'
import AccentText from '../../AccentText'

const MyProjectsCard = () => {
  return (
    <div className='flex flex-col w-full h-full'>      
    
        <span className="lg:text-6xl text-5xl font-black"><AccentText>My Projects</AccentText></span>

        
    </div>
  )
}

export default MyProjectsCard