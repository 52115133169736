import React from "react";
import "./NavBar.css";
import { FaLinkedin, FaGithub, FaFacebook, FaInstagram } from "react-icons/fa";
import { motion, useScroll, useTransform } from "framer-motion";

const NavBar = () => {
  const SOCIAL_ICON_SIZE = 18;
  const numSections = 4;
  const { scrollYProgress } = useScroll();
  const pageScrollProgress = useTransform(
    scrollYProgress,
    [0, 1 / numSections],
    [0, 1]
  );

  const socialsX = useTransform(pageScrollProgress, [0, 1], ["0%", "100%"]);
  const socialsOpacity = useTransform(
    pageScrollProgress,
    [0, 1],
    ["100%", "0%"]
  );
  const nameX = useTransform(pageScrollProgress, [0, 1], ["0%", "-100%"]);
  const nameOpacity = useTransform(pageScrollProgress, [0, 1], ["100%", "0%"]);
  const buttonScale = useTransform(pageScrollProgress, [0, 1], [0, 1]);

  return (
    <div className="relative flex w-[100%] h-[100px] max-w-[1366px] px-5 ">
      <motion.span
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="flex gap-2 font-bold  items-center overflow-clip"
      >
        <div className="relative">
          <img
            src={"./Logo/KeionLogo.svg"}
            className="w-4 z-[99]"
            alt="Objects"
          />
        </div>
        <motion.span style={{ x: nameX, opacity: nameOpacity, zIndex: 60 }}>
          Keion Vergara
        </motion.span>
      </motion.span>
      {/* <div className="absolute top-0 bottom-0 left-[-15px] grid place-items-center justify-center  ">
         <motion.div className="origin-center bg-white w-12 h-12 rounded-full" style={{scale: buttonScale}}/>

        </div> */}

      <motion.div
        className="absolute flex gap-2 items-center inset-y-0 right-0"
        style={{ right: socialsX, opacity: socialsOpacity }}
      >
        <a href="https://www.linkedin.com/in/keionv">
          <FaLinkedin size={SOCIAL_ICON_SIZE} />
        </a>
        <a href="https://github.com/notKeion/">
          <FaGithub size={SOCIAL_ICON_SIZE} />
        </a>
        <a href="https://www.instagram.com/keionvergara/">
          <FaInstagram size={SOCIAL_ICON_SIZE} />
        </a>
        <a href="#">
          <FaFacebook size={SOCIAL_ICON_SIZE} />
        </a>
      </motion.div>
    </div>
  );
};

export default NavBar;
