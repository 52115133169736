import { useState } from "react";
import { motion } from "framer-motion";

const GradientButton = ({ children, isButton }) => {
  const [isHovered, setHovered] = useState(false);
  const variants = {
    initial: {
      backgroundPosition: "0 50%"
    },
    animate: {
      backgroundPosition: ["0, 50%", "100% 50%"],
    },
  };
  const transition = {
    duration: 2,
    repeat: Infinity,
    repeatType: "reverse",
  };

  return (
    <button
      className="p-[2px] relative group flex"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {/* Inner Gradient Border*/}
      <motion.div
        variants={variants}
        initial="initial"
        animate={isHovered ? "animate" : "initial"}
        transition={transition}
        style={{ backgroundSize: "400% 400%" }}
        className="absolute opacity-60 group-hover:opacity-100 ease-out duration-300 transition-all rounded-full will-change-transform inset-0 z-[1]  bg-[radial-gradient(circle_farthest-side_at_0_100%,#00ccb1,transparent),radial-gradient(circle_farthest-side_at_100%_0,#7b61ff,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#ffc414,transparent),radial-gradient(circle_farthest-side_at_0_0,#1ca0fb,#141316)]"
      />

      <div className="relative z-[10] m-0">{children}</div>
    </button>
  );
};

export default GradientButton;
