import React from "react";
import BackgroundGradient from "../../BackgroundGradient";


const SigmaChiCard = () => {
  return (
    <div className="grid w-full h-full grid-cols-2 grid-rows-1">
      {/* <div className="flex col-span-1 justify-center items-center ">
        <span className="font-black lg:text-4xl text-xl">
          Sigma Chi Fraternity Member
        </span>
      </div> */}
      <div
        className="flex h-full w-full col-span-2"
        style={{
          maskImage: "url(./SigmaChiLetters.svg)",
          maskRepeat: "no-repeat",
          maskSize: "100% 100%",
          
        }}
      >
        <BackgroundGradient disableRounding className={'w-[105%] h-[105%]'}/>
        {/* <div className="flex h-full w-full bg-[radial-gradient(circle_farthest-side_at_0_100%,#00ccb1,transparent),radial-gradient(circle_farthest-side_at_100%_0,#7b61ff,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#ffc414,transparent),radial-gradient(circle_farthest-side_at_0_0,#1ca0fb,#141316)]" /> */}
      </div>
    </div>
  );
};

export default SigmaChiCard;
